'use strict';

/**
 * Duplicates the same function in `csrf-token.js`, which is intended for non-modular JS.
 * @returns {string} The CSRF token.
 */
function fetchCsrfToken() {
  return document.getElementById('js-csrf-token-meta').getAttribute('content');
}

export {fetchCsrfToken};